<template>
  <div class="setting-container content-container">
    <div class="left-container">
      <template v-for="(el, index) in configTemplateArr" :key="index">
        <div
          class="config-emplate"
          :class="
            configTemplateSelect.id == el.id ? 'config-emplate-active' : ''
          "
          @click="changeConfigTemplate(el)"
        >
          {{ el.title }}
        </div>
      </template>
    </div>
    <div class="right-container">
      <ContentTitle>{{ configTemplateSelect.title }}</ContentTitle>
      <Wtable
        :columns="columns"
        :showPagin="showPagin"
        :pagination="pagination"
        :tableData="tableData"
        @currentChange="currentChange"
        @update="openEditDialog"
      ></Wtable>
    </div>
    <Wdialog
      ref="editDialog"
      title="编辑参数"
      width="50%"
      @wConfirm="handleSure"
    >
      <el-form
        ref="editFormRef"
        :model="editFormData"
        :rules="editFormRules"
        label-position="top"
        class="role-form"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="title" label="参数名称">
              <el-select
                v-model="editFormData.title"
                placeholder="请选择参数名称"
              >
                <el-option
                  v-for="item in configTemplateArr"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="16">
            <el-form-item prop="value" label="参数值">
              <el-input
                v-model="editFormData.value"
                autocomplete="off"
                placeholder="请输入参数值"
                clearable
              /> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="请输入参数说明" prop="desc">
              <el-input
                type="textarea"
                rows="6"
                v-model="editFormData.desc"
                autocomplete="off"
                placeholder="请输入参数说明"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </Wdialog>
  </div>
</template>

<script>
import { ref } from "vue";
import Wtable from "@/components/wTable/wTable.vue";
import Wdialog from "@/components/wDialog/wDialog.vue";
import ContentTitle from "@/components/contentTitle/contentTitle.vue";
import config from "./index.json";

export default {
  components: {
    Wtable,
    Wdialog,
    ContentTitle,
  },
  setup() {
    const configTemplateArr = ref([
      {
        id: 1,
        title: "鲁滨逊啊开票参数配置名字真的好长",
      },
      {
        id: 2,
        title: "超好喝真的很不错配置名字真的好长",
      },
      {
        id: 3,
        title: "优酸乳果果西名字真的好长",
      },
      {
        id: 4,
        title: "黑暗森林法则名字真的好长",
      },
      {
        id: 5,
        title: "奥弗斯特洛夫斯基名字真的好长",
      },
    ]);
    const configTemplateSelect = ref({});
    configTemplateSelect.value = configTemplateArr.value[0];
    function changeConfigTemplate(el) {
      configTemplateSelect.value = el;
    }

    const columns = config.tableConfig.list;
    const showPagin = config.tableConfig.showPagin;
    const tableData = ref([
      {
        title: 5,
        value: "999",
        desc: "我真的有很多很多很多参数说明",
      },
    ]);

    const pagination = ref({ total: 9 });
    const currentChange = (page) => {
      console.log(page);
    };
    const editDialog = ref(null);
    const editFormRef = ref(null);
    const editFormRules = {
      title: [{ required: true, message: "请选择参数名称", trigger: "change" }],
      value: [{ required: true, message: "请输入参数值", trigger: "blur" }],
      desc: [
        {
          required: true,
          message: "请输入参数说明",
          trigger: "blur",
        },
      ],
    };
    const editFormData = ref({
      title: "",
      value: "",
      desc: "",
    });

    function openEditDialog(data) {
      const rowData = JSON.parse(JSON.stringify(data.row));
      editFormData.value = rowData;
      editDialog.value.show();
    }
    function handleSure() {
      if (editFormRef.value) {
        editFormRef.value.validate((valid) => {
          if (valid) {
            console.log(editFormData.value);
            editDialog.value.close();
          }
        });
      }
    }

    return {
      configTemplateArr,
      configTemplateSelect,
      changeConfigTemplate,
      columns,
      showPagin,
      tableData,
      pagination,
      currentChange,
      openEditDialog,
      editDialog,
      editFormRef,
      editFormRules,
      editFormData,
      handleSure,
    };
  },
};
</script>

<style lang='scss'>
.setting-container {
  .left-container {
    text-align: justify;
    .config-emplate {
      padding: 14px 0 14px 16px;
      cursor: pointer;
      color: var(--text-third-color);
      &:hover {
        color: var(--theme-color);
        background-color: var(--search-bg-color);
      }
    }
    .config-emplate-active {
      color: var(--theme-color);
      background-color: var(--search-bg-color);
    }
  }
}
</style>